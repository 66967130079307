import { useState } from "react";
import { saveCredentials } from "../services/dataService";

export default function SecretEntry({ organization, setAdminModule }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function submit(e) {
    e.preventDefault();
  }

  function validateCredentials() {
    if (username !== "" && password !== "") {
      saveCredentials(username, password, organization).then(
        setAdminModule("")
      );
    }
  }

  return (
    <form onSubmit={submit} className="panel">
      <div className="panel-header">
        <h3>API Credentials</h3>
      </div>
      <div className="panel-body">
        <div className="panel-primary-item">
          <div className="label">Username:</div>
          <input
            className="input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="panel-primary-item">
          <div className="label">API Token:</div>
          <input
            className="input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="button-primary"
          onClick={() => validateCredentials()}
        >
          Save
        </button>
        <div className="text">Once saved, you cannot edit your API Token.</div>
        <div className="text">
          If there is an error in your entry, you will need to clear and
          re-input your credentials.
        </div>
      </div>
    </form>
  );
}
