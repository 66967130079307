import { globalData } from "../services/dataService.js";

export default function AdminMenu({ setAdminModule }) {
  return (
    <div className="side-menu">
      <h2 className="side-menu-header">Administration</h2>
      <div className="side-menu-item" onClick={() => setAdminModule("access")}>
        Access
      </div>
      {(globalData.access.get("llc") === "No Access" ||
        globalData.access.get("llc") === "Admin Only" ||
        globalData.access.get("llc") === "Read Only" ||
        globalData.access.get("llc") === "All Access") && (
        <div className="side-menu-item" onClick={() => setAdminModule("llc")}>
          LLC Configuration
        </div>
      )}
      <div
        className="side-menu-item"
        onClick={() => setAdminModule("messages")}
      >
        Messages
      </div>
      <div
        className="side-menu-item"
        onClick={() => setAdminModule("orgDetails")}
      >
        Organization Details
      </div>
      <div className="side-menu-item" onClick={() => setAdminModule("terms")}>
        Terms
      </div>
      <div className="side-menu-item" onClick={() => setAdminModule("users")}>
        Users
      </div>
    </div>
  );
}
