import { signOutUser } from "../../services/authService";
import { globalData } from "../../services/dataService";

export default function SideMenu({
  toggleMenu,
  setCurrentModule,
  user,
  profile,
}) {
  return (
    <>
      <div className="new-side-menu-background" onClick={() => toggleMenu()} />
      <div className="new-side-menu">
        <div className="new-side-menu-header right">
          <div className="fa fa-times" onClick={() => toggleMenu()} />
        </div>
        <button
          className="new-side-menu-item"
          onClick={() => setCurrentModule("home")}
        >
          Home
        </button>
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("llc") === "All Access" ||
            globalData.access.get("llc") === "Read Only" ||
            (globalData.access.get("llc") === "Admin Only" &&
              profile.admin)) && (
            <button
              className="new-side-menu-item"
              onClick={() => setCurrentModule("llc")}
            >
              LLC
            </button>
          )}
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("earlyArrival") === "All Access" ||
            globalData.access.get("earlyArrival") === "Read Only" ||
            (globalData.access.get("earlyArrival") === "Admin Only" &&
              profile.admin)) && (
            <button
              className="new-side-menu-item"
              onClick={() => setCurrentModule("earlyArrival")}
            >
              Early Arrival
            </button>
          )}
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("lateStay") === "All Access" ||
            globalData.access.get("lateStay") === "Read Only" ||
            (globalData.access.get("lateStay") === "Admin Only" &&
              profile.admin)) && (
            <button
              className="new-side-menu-item"
              onClick={() => setCurrentModule("lateStay")}
            >
              Late Stay
            </button>
          )}
        {user && profile && profile.verified && profile.active && (
          <button
            className="new-side-menu-item"
            onClick={() => setCurrentModule("help")}
          >
            Help
          </button>
        )}
        {user &&
          profile &&
          profile.admin &&
          profile.active &&
          profile.verified && (
            <button
              className="new-side-menu-item"
              onClick={() => setCurrentModule("admin")}
            >
              Settings
            </button>
          )}
        {user && (
          <button className="new-side-menu-item" onClick={() => signOutUser()}>
            Sign Out
          </button>
        )}
      </div>
    </>
  );
}
