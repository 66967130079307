import { useEffect } from "react";

export default function HelpAbout() {
  useEffect(() => {
    //fetch("../../README.md").then((r) => r.text().then((text) => (input = text)));
    fetch("../../README.txt").then((r) => console.log("Line 10: " + r.text()));
    //fetch("../../README.txt").then((r) => setInput(r));
  }, []);

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>About</h4>
      </div>
      <div className="panel-body"></div>
    </div>
  );
}
