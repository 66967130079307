import { useState } from "react";
import { globalData } from "../services/dataService";
import GroupOwnerEdit from "./GroupOwnerEdit";

export default function ActionMenu({
  group,
  profile,
  viewEdit,
  setViewEdit,
  deleter,
  validate,
  setGroup,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [changeOwner, setChangeOwner] = useState(false);

  function submit(e) {
    e.preventDefault();
  }

  function ConfirmDelete() {
    return (
      <div className="action-menu">
        <button
          className="button-primary"
          onClick={() => setConfirmDelete(false)}
        >
          Cancel
        </button>
        <button
          className="button-primary"
          onClick={() => deleter(group.id)}
          disabled={group.groupSize !== 0}
          title={
            group.groupSize !== "0" ? "Can't delete a group with members." : ""
          }
        >
          Delete "{group.name}"?
        </button>
      </div>
    );
  }

  return (
    <div className="wrapper">
      <form onSubmit={submit} className="action-menu">
        {viewEdit === "view" && (
          <button className="button-primary" onClick={() => setGroup(null)}>
            Back
          </button>
        )}
        {viewEdit === "edit" && (
          <button
            className="button-primary"
            onClick={() => setViewEdit("view")}
          >
            Cancel
          </button>
        )}
        {viewEdit === "edit" && (
          <button className="button-primary" onClick={() => validate()}>
            Save Group Info
          </button>
        )}
        {globalData.access.get("earlyArrival") !== "Read Only" &&
          viewEdit === "view" && (
            <button
              className="button-primary"
              onClick={() => setViewEdit("edit")}
            >
              Edit Group Info
            </button>
          )}
        {globalData.access.get("earlyArrival") !== "Read Only" && (
          <button
            className="button-primary"
            onClick={() => setConfirmDelete(true)}
          >
            Delete Group
          </button>
        )}
        {profile.admin &&
          globalData.access.get("earlyArrival") === "All Access" && (
            <button
              className="button-primary"
              onClick={() => setChangeOwner(!changeOwner)}
            >
              Change Owner
            </button>
          )}
      </form>
      {confirmDelete && <ConfirmDelete />}
      {changeOwner && (
        <GroupOwnerEdit
          group={group}
          profile={profile}
          setChangeOwner={setChangeOwner}
          groupType="EarlyArrival"
        />
      )}
    </div>
  );
}
