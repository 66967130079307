import { saveKey } from "../services/dataService";

export default function AccessKeyEdit({ selectedKey, setKey, selectedStatus }) {
  function updateKey(newStatus) {
    if (selectedStatus === "admin") {
      selectedKey.adminStatus = newStatus;
      saveKey(selectedKey).then(setKey(null));
    } else if (selectedStatus === "user") {
      selectedKey.userStatus = newStatus;
      saveKey(selectedKey).then(setKey(null));
    }
  }

  return (
    <div className="popup-wrapper">
      <div className="popup">
        <div className="panel">
          <div className="panel-body">
            <div
              onClick={() => updateKey("All Access")}
              className="button-link"
            >
              All Access
            </div>
            {/* // DEPRECATED 2024-12-31 as part of separate user/admin keys
            <div
              onClick={() => updateKey("Admin Only")}
              className="button-link"
            >
              Admin Only
            </div>*/}
            <div onClick={() => updateKey("Read Only")} className="button-link">
              Read Only
            </div>
            <div onClick={() => updateKey("No Access")} className="button-link">
              No Access
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
