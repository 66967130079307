import { db } from "../firebaseConfig";
import {
  collection,
  query,
  getDocs,
  addDoc,
  where,
  doc,
  documentId,
  updateDoc,
  deleteDoc,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const getLateStayData = httpsCallable(functions, "getLateStayData");

export async function getLateStays(orgCode, termId) {
  const snapshot = await getDocs(
    query(
      collection(db, "lateStays"),
      where("orgCode", "==", orgCode),
      where("termId", "==", termId),
      orderBy("last")
    )
  );
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function getLateStayGroups(uid, orgCode, type, termId) {
  let snapshot;
  if (type === "my") {
    snapshot = await getDocs(
      query(
        collection(db, "lateStayGroups"),
        where("ownerId", "==", uid),
        where("termId", "==", termId),
        orderBy("name")
      )
    );
  } else if (type === "all") {
    snapshot = await getDocs(
      query(
        collection(db, "lateStayGroups"),
        where("orgCode", "==", orgCode),
        where("termId", "==", termId),
        orderBy("name")
      )
    );
  }
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function createLateStayGroup(name, date, term, orgCode, ownerId) {
  const data = {
    ownerId,
    orgCode,
    term: term,
    name,
    date,
    termId: term.id,
    status: "Pending",
    groupSize: 0,
  };
  const docRef = await addDoc(collection(db, "lateStayGroups"), data);
  return docRef;
}

export async function saveLateStayGroup(group) {
  const groupRef = doc(db, "lateStayGroups", group.id);
  const data = {
    name: group.name,
    date: group.date,
    term: group.term,
    status: group.status,
    ownerId: group.ownerId,
  };
  await updateDoc(groupRef, data);
  if (group.updatedDate === true) {
    getLateStayGroupMembers(group).then((members) => {
      members.forEach((m) => {
        deleteLateStayGroupMember(m.id, group.id).then(() => {
          const docRef = doc(db, "lateStays", m.id);
          getDoc(docRef).then((docSnap) =>
            addToLateStayGroup(docSnap.data().orgId, group)
          );
        });
      });
    });
    group.updatedDate = false;
  }
}

export async function deleteLateStayGroup(groupId, ownerId, setGroups) {
  await deleteDoc(doc(db, "lateStayGroups", groupId));
}

export async function getLateStayGroupMembers(group) {
  const groupMemberships = await getDocs(
    query(
      collection(db, "lateStayGroupMemberships"),
      where("groupId", "==", group.id)
    )
  );
  if (!groupMemberships.empty) {
    const memberIds = [];
    groupMemberships.forEach((member) =>
      memberIds.push(member.data().memberId)
    );

    let subList = [];
    for (var i = 0; i < memberIds.length; i += 10) {
      subList.push(
        memberIds.slice(
          i,
          i + 10 > memberIds.length ? memberIds.length : i + 10
        )
      );
    }
    let promises = [];

    subList.forEach((element) => {
      promises.push(
        getDocs(
          query(collection(db, "lateStays"), where(documentId(), "in", element))
        )
      );
    });

    return await Promise.all(promises).then((results) =>
      results
        .map((snapshot) =>
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        )
        .flat()
    );
  }
}

export async function addToLateStayGroup(id, group) {
  console.log("qewrrty");
  const systemData = await getLateStayData({
    orgCode: group.orgCode,
    orgId: id,
  });
  console.log("abcdefg");
  const membersSnapshot = await getDocs(
    query(
      collection(db, "lateStays"),
      where("orgId", "==", id),
      where("termId", "==", group.termId)
    )
  );
  if (!membersSnapshot.empty) {
    const data = { memberId: membersSnapshot.docs[0].id, groupId: group.id };
    await addDoc(collection(db, "lateStayGroupMemberships"), data);
    if (
      Date.parse(group.date) >=
        Date.parse(membersSnapshot.docs[0].data().earliestDate) ||
      membersSnapshot.docs[0].data().earliestDate === "0"
    ) {
      const memberRef = doc(db, "lateStays", membersSnapshot.docs[0].id);
      const data = { latestDate: group.date, latestGroup: group.name };
      await updateDoc(memberRef, data);
    }
  } else {
    const memberData = {
      first: systemData.data.nameFirst,
      last: systemData.data.nameLast,
      latestDate: group.date,
      latestGroup: group.name,
      orgCode: group.orgCode,
      orgId: id,
      termId: group.termId,
      systemId: systemData.data.sysId,
    };
    const memberRef = await addDoc(collection(db, "lateStays"), memberData);
    const groupMembershipData = { memberId: memberRef.id, groupId: group.id };
    await addDoc(
      collection(db, "lateStayGroupMemberships"),
      groupMembershipData
    );
  }
  const membershipsSnap = await getDocs(
    query(
      collection(db, "lateStayGroupMemberships"),
      where("groupId", "==", group.id)
    )
  );
  const groupRef = doc(db, "lateStayGroups", group.id);
  await updateDoc(groupRef, { groupSize: membershipsSnap.docs.length });
}

export async function deleteLateStayGroupMember(memberId, groupId) {
  const groupMemberships = await getDocs(
    query(
      collection(db, "lateStayGroupMemberships"),
      where("groupId", "==", groupId),
      where("memberId", "==", memberId)
    )
  );
  if (!groupMemberships.empty) {
    const groupMembership = groupMemberships.docs[0];
    await deleteDoc(doc(db, "lateStayGroupMemberships", groupMembership.id));
    const memberRef = doc(db, "lateStays", memberId);
    const remainingMemberships = await getDocs(
      query(
        collection(db, "lateStayGroupMemberships"),
        where("memberId", "==", memberId)
      )
    );
    if (!remainingMemberships.empty) {
      const groupIds = [];
      remainingMemberships.docs.forEach((doc) =>
        groupIds.push(doc.data().groupId)
      );
      const groups = await getDocs(
        query(
          collection(db, "lateStayGroups"),
          where(documentId(), "in", groupIds)
        )
      );
      let latest = groups.docs[0].data().date;
      let latestGroup = "";
      groups.docs.forEach((g) => {
        if (Date.parse(g.data().date) >= Date.parse(latest)) {
          latest = g.data().date;
          latestGroup = g.data().name;
        }
      });
      await updateDoc(memberRef, {
        latestDate: latest,
        latestGroup: latestGroup,
      });
    } else {
      await updateDoc(memberRef, { latestDate: "0", latestGroup: "" });
    }
  }
  const membershipsSnap = await getDocs(
    query(
      collection(db, "lateStayGroupMemberships"),
      where("groupId", "==", groupId)
    )
  );
  const groupRef = doc(db, "lateStayGroups", groupId);
  await updateDoc(groupRef, { groupSize: membershipsSnap.docs.length });
}
