import AccessList from "./AccessList";
import AccessKeyEdit from "./AccessKeyEdit";
import { getAccessKeys } from "../services/dataService";
import { useState, useEffect } from "react";

export default function Access({ profile }) {
  const [selectedKey, setKey] = useState(null);
  const [accessKeys, setAccessKeys] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    getAccessKeys(profile.orgCode).then(setAccessKeys);
  }, [profile]);

  return (
    <div>
      <div className="module-header">
        <h3>Access</h3>
      </div>
      <AccessList
        profile={profile}
        accessKeys={accessKeys}
        setKey={setKey}
        selectedKey={selectedKey}
        setSelectedStatus={setSelectedStatus}
      />
      {selectedKey !== null && (
        <AccessKeyEdit
          selectedKey={selectedKey}
          setKey={setKey}
          selectedStatus={selectedStatus}
        />
      )}
    </div>
  );
}
