import { SignOut } from "../services/authService.js";
import { globalData } from "../services/dataService.js";
import partnerportallogo from "../assets/partnerportallogo.png";

export default function Header({ user, setModule, profile, toggleMenu }) {
  return (
    <div className="header">
      <h1>
        {<div className="fa fa-bars" onClick={() => toggleMenu()} />}
        <img src={partnerportallogo} alt="logo" height="30" width="30" />
        Partner Portal{profile && ` - ${profile.orgCode}`}
      </h1>
      <div style={{ display: "none" }}>
        {user && (
          <button className="button-menu" onClick={() => setModule("home")}>
            Home
          </button>
        )}
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("llc") === "All Access" ||
            globalData.access.get("llc") === "Read Only" ||
            (globalData.access.get("llc") === "Admin Only" &&
              profile.admin)) && (
            <button className="button-menu" onClick={() => setModule("llc")}>
              LLC
            </button>
          )}
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("earlyArrival") === "All Access" ||
            globalData.access.get("earlyArrival") === "Read Only" ||
            (globalData.access.get("earlyArrival") === "Admin Only" &&
              profile.admin)) && (
            <button
              className="button-menu"
              onClick={() => setModule("earlyArrival")}
            >
              Early Arrival
            </button>
          )}
        {user &&
          profile &&
          profile.verified &&
          profile.active &&
          (globalData.access.get("lateStay") === "All Access" ||
            globalData.access.get("lateStay") === "Read Only" ||
            (globalData.access.get("lateStay") === "Admin Only" &&
              profile.admin)) && (
            <button
              className="button-menu"
              onClick={() => setModule("lateStay")}
            >
              Late Stay
            </button>
          )}
        {user && profile && profile.verified && profile.active && (
          <button className="button-menu" onClick={() => setModule("help")}>
            Help
          </button>
        )}
        {user &&
          profile &&
          profile.admin &&
          profile.active &&
          profile.verified && (
            <button className="button-menu" onClick={() => setModule("admin")}>
              Admin
            </button>
          )}
        {user && <SignOut />}
      </div>
    </div>
  );
}
