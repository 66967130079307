export function displayDate(dateString) {
  if (dateString.length === 10)
    return (
      dateString.substring(5, 7) +
      "/" +
      dateString.substring(8, 10) +
      "/" +
      dateString.substring(0, 4)
    );

  return "0";
}

export function isDateEarlier(newDate, existingDate) {
  return (
    Date.parse(newDate) <= Date.parse(existingDate) || existingDate === "0"
  );
}
