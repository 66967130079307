import { useState } from "react";
import { clearCredentials } from "../services/dataService";

export default function SecretDetails({ organization, setAdminModule }) {
  const [confirmClear, setConfirm] = useState(false);

  return (
    <div className="panel">
      <div className="panel-header">
        <h3>API Credentials</h3>
      </div>
      <div className="panel-body">
        <div className="text">
          For security purposes, API Credentials can't be edited.
        </div>
        <div className="text">
          If changes are necessary, use the button below to clear the saved
          credentials.
        </div>
        <div className="text">
          Once cleared, you can then enter new credentials.
        </div>
        <button className="button-primary" onClick={() => setConfirm(true)}>
          Reset Credentials
        </button>
        {confirmClear && (
          <>
            <button
              className="button-secondary"
              onClick={() => setConfirm(false)}
            >
              Cancel
            </button>
            <button
              className="button-primary"
              onClick={() =>
                clearCredentials(organization).then(setAdminModule(""))
              }
            >
              Reset
            </button>
          </>
        )}
      </div>
    </div>
  );
}
