import { useState, useEffect } from "react";
import { getOrganization, saveOrganization } from "../services/dataService";
import SecretEntry from "./SecretEntry";
import SecretDetails from "./SecretDetails";

export default function OrgDetails({ orgCode, setAdminModule }) {
  const [organization, setOrganization] = useState("");

  useEffect(() => {
    getOrganization(orgCode).then(setOrganization);
  }, [orgCode]);

  return (
    <div className="wrapper">
      <div className="action-menu">
        <button
          onClick={() => saveOrganization(organization)}
          className="button-primary"
        >
          Save
        </button>
      </div>
      <div className="panel">
        <div className="panel-header">
          <h3>Organization Details</h3>
        </div>
        <div className="panel-body">
          <div className="panel-primary-item">
            <div className="label">Name:</div>
            <div className="text">{organization.name}</div>
          </div>
          <div className="panel-primary-item">
            <div className="label">Org Code:</div>
            <div className="text">{organization.orgCode}</div>
          </div>
          <div className="panel-primary-item">
            <div className="label">Active:</div>
            <div className="text">{organization.active ? "True" : "False"}</div>
          </div>
          <div className="panel-primary-item">
            <div className="label">Allowed Domains:</div>
            <input
              className="input"
              defaultValue={organization.domains}
              onChange={(e) => (organization.domains = e.target.value)}
            />
          </div>
          <div className="text">
            The above domains will be the only allowed domains for email
            addresses of users when creating accounts. Separate multiple domains
            with a comma or semicolon.
          </div>
          {/*<div className="panel-primary-item">
          <div className="label">Early Arrival Field ID:</div>
          <input
            className="input"
            defaultValue={organization.fieldId}
            onChange={(e) => (organization.fieldId = e.target.value)}
          />
        </div>
        <div className="text">
          The Field ID above should correspond to the Entry Custom Field to be
          updated/set when processing early arrivals into StarRez.
        </div>*/}
        </div>
      </div>
      {organization.secret !== "" && (
        <SecretDetails
          organization={organization}
          setAdminModule={setAdminModule}
        />
      )}
      {organization.secret === "" && (
        <SecretEntry
          organization={organization}
          setAdminModule={setAdminModule}
        />
      )}
    </div>
  );
}
